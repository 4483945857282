@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
    url(./fonts/Lato/Lato-Light.woff2) format('woff2'),
    url(./fonts/Lato/Lato-Light.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato'), local('Lato Regular'), local('Lato-Regular'),
    url(./fonts/Lato/Lato.woff2) format('woff2'),
    url(./fonts/Lato/Lato.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url(./fonts/Lato/Lato-Bold.woff2) format('woff2'),
    url(./fonts/Lato/Lato-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
    url(./fonts/Lato/Lato-Light-Italic.woff2) format('woff2'),
    url(./fonts/Lato/Lato-Light-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato Italic'), local('Lato-Italic'),
    url(./fonts/Lato/Lato-Italic.woff2) format('woff2'),
    url(./fonts/Lato/Lato-Italic.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url(./fonts/Lato/Lato-Bold-Italic.woff2) format('woff2'),
    url(./fonts/Lato/Lato-Bold-Italic.woff) format('woff');
}

/*

  Base Font Declaration

  These are the only un-scoped styles applied to the entire page.
  All of our size calculations are based on rem, which uses the font size
  of the root element.

*/

html {
  font-size: 87.5%;
}

body {
  font-size: 14px;
  font-size: 1rem;
  line-height: 1;
}

/*

  Variables

*/

html {
  /* Colors */
  --dwUtilities-color-navy: #355d8a;
  --dwUtilities-color-teal: #7ec3b3;
  --dwUtilities-color-blue: #6290c3;
  --dwUtilities-color-green: #67bf70;
  --dwUtilities-color-yellow: #f6bd68;
  --dwUtilities-color-red: #f36866;

  --dwUtilities-color-midnight: #323d48;
  --dwUtilities-color-slate: #5d6f85;
  --dwUtilities-color-nickel: #8c9caf;
  --dwUtilities-color-silver: #aeb9c7;
  --dwUtilities-color-magnesium: #f7f7f7;
  --dwUtilities-color-white: #ffffff;

  --dwUtilities-color-midnightShadow: rgba(50, 61, 72, 0.6);
  --dwUtilities-color-nickelShadow: rgba(140, 156, 175, 0.75);
  --dwUtilities-color-border: rgba(140, 156, 175, 0.5);

  /* Fonts */
  --dwUtilities-font-base: 'Lato', Helvetica, Arial, sans-serif;

  /* Sizes */
  --dwUtilities-size-2px: 0.14285714285rem;
  --dwUtilities-size-4px: 0.28571429rem;
  --dwUtilities-size-6px: 0.42857142857rem;
  --dwUtilities-size-8px: 0.571428571rem;
  --dwUtilities-size-12px: 0.857142857rem;
  --dwUtilities-size-14px: 1rem;
  --dwUtilities-size-16px: 1.142857143rem;
  --dwUtilities-size-18px: 1.285714286rem;
  --dwUtilities-size-20px: 1.428571429rem;
  --dwUtilities-size-22px: 1.571428571rem;
  --dwUtilities-size-24px: 1.714285714rem;
  --dwUtilities-size-26px: 1.85714285714rem;
  --dwUtilities-size-28px: 2rem;
  --dwUtilities-size-32px: 2.28571428571rem;
  --dwUtilities-size-36px: 2.571428571rem;
  --dwUtilities-size-48px: 3.428571429rem;
  --dwUtilities-size-64px: 4.57142857rem;
  --dwUtilities-size-72px: 5.142857143rem;
}

/*

  Typography

*/

.dwUtilities {
  font-family: var(--dwUtilities-font-base);
  color: var(--dwUtilities-color-midnight);
}

.dwUtilities h1 {
  font-size: var(--dwUtilities-size-24px);
}

.dwUtilities h2 {
  font-size: var(--dwUtilities-size-20px);
}

.dwUtilities h3 {
  font-size: var(--dwUtilities-size-18px);
}

.dwUtilities h4 {
  font-size: var(--dwUtilities-size-16px);
}

.dwUtilities a {
  color: var(--dwUtilities-color-blue);
  font-weight: bold;
  -webkit-text-decoration: solid;
          text-decoration: solid;
}

.dwUtilities a:hover,
.dwUtilities a:focus {
  text-decoration: underline;
}

.dwUtilities {
  --dwUtilities-baseHeight: 100vh;

  --dwUtilities-headerHeight: var(--dwUtilities-size-48px);
  --dwUtilities-headerPadding: var(--dwUtilities-size-6px);
  --dwUtilities-headerTotalSize: calc(
    var(--dwUtilities-headerHeight) + 2 * var(--dwUtilities-headerPadding)
  );

  --dwUtilities-zIndex-header: 10;
  --dwUtilities-zIndex-modal: 50;
}

/*

  Header

*/

.dwUtilities__header {
  background-color: var(--dwUtilities-color-white);
  padding: var(--dwUtilities-headerPadding);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px var(--dwUtilities-size-2px) var(--dwUtilities-size-4px) 0px var(--dwUtilities-color-nickelShadow);

  height: var(--dwUtilities-headerHeight);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--dwUtilities-zIndex-header);
}

.dwUtilities__header h1 {
  color: var(--dwUtilities-color-slate);
}

.dwUtilities__header .dwUtilities__headerSide {
  margin: var(--dwUtilities-headerPadding);
}

/*

  View Container

*/

.dwUtilities__viewContainer {
  min-height: calc(
    var(--dwUtilities-baseHeight) - var(--dwUtilities-headerTotalSize)
  );
  max-width: 100vw;
  margin: var(--dwUtilities-headerTotalSize) 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.dwUtilities__viewContainer .dwUtilities__main {
  flex: 1 0 auto;
}

.dwUtilities__viewContainer .dwUtilities__footer {
  flex: 0 0 auto;
}

/*

  Main

*/

.dwUtilities__main {
  background-color: var(--dwUtilities-color-magnesium);
  padding: var(--dwUtilities-size-24px) 0;

  display: flex;
  align-items: flex-start;
}

.dwUtilities__main .dwUtilities__mainSideColumn {
  flex: 2 1 auto;
}

.dwUtilities__main .dwUtilities__mainColumn {
  flex: 8 1 auto;
  max-width: 60rem;
  margin: 0 var(--dwUtilities-size-12px);
  overflow-x: hidden;
}

/*

  Card

*/

.dwUtilities__card {
  --dwUtilities-card-padding: var(--dwUtilities-size-24px);
  --dwUtilities-card-paddingNegative: calc(-1 * var(--dwUtilities-size-24px));

  background-color: var(--dwUtilities-color-white);
  border: 1px solid var(--dwUtilities-color-border);
  border-radius: 2px;
  font-family: var(--dwUtilities-font-base);
  min-height: 1rem;
  padding: var(--dwUtilities-card-padding);
  margin: var(--dwUtilities-size-16px) 0;
  overflow-x: scroll;
}

.dwUtilities__card .dwUtilities__cardFooter {
  margin: var(--dwUtilities-card-padding)
    var(--dwUtilities-card-paddingNegative)
    var(--dwUtilities-card-paddingNegative);
  padding: var(--dwUtilities-size-18px);
  border-top: 1px solid var(--dwUtilities-color-border);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*

  Footer

*/

.dwUtilities__footer {
  background-color: var(--dwUtilities-color-magnesium);
  padding: var(--dwUtilities-size-26px);
  border-top: 1px solid var(--dwUtilities-color-border);
  text-align: center;
}

.dwUtilities__footerLinkList {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin-block: 0;
  margin-inline: 0;
  padding-inline: 0;
}

.dwUtilities__footerLink:not(:last-of-type) {
  margin: 0 var(--dwUtilities-size-14px) 0 0;
}

.dwUtilities__footerCopyright {
  color: var(--dwUtilities-color-slate);
}

/*

  Modal

*/

.dwUtilities__modalOverlay {
  background-color: var(--dwUtilities-color-midnightShadow);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--dwUtilities-zIndex-modal);
  max-height: 100vh;
  overflow: hidden;
}

.dwUtilities__modalOverlay--hidden {
  /* Hides modal from everyone, including screen readers */
  display: none;
}

.dwUtilities__modal {
  background-color: var(--dwUtilities-color-white);
  border: 1px solid var(--dwUtilities-color-border);
  border-radius: 2px;
  flex: 1 1 auto;
  font-family: var(--dwUtilities-font-base);
  max-width: 40rem;
  max-height: 100vh;
  overflow-y: scroll;
  font-family: var(--dwUtilities-font-base);
}

.dwUtilities__modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--dwUtilities-size-22px) var(--dwUtilities-size-32px);
  border-bottom: 1px solid var(--dwUtilities-color-border);
  margin: 0 0 var(--dwUtilities-size-22px);
}

.dwUtilities__modalHeader--simple {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: var(--dwUtilities-size-8px);
}

.dwUtilities__modalHeader h2 {
  margin: 0;
}

.dwUtilities__modalBody {
  padding: 0 var(--dwUtilities-size-32px);
  max-height: 25rem;
  overflow-y: scroll;
}

.dwUtilities__modalFooter {
  text-align: right;
  border-top: 1px solid var(--dwUtilities-color-border);
  padding: var(--dwUtilities-size-22px) var(--dwUtilities-size-32px);
  margin: var(--dwUtilities-size-22px) 0 0;
}

/*

  Small Screens

*/

@media (max-width: 767px) {
  .dwUtilities__main {
    padding: var(--dwUtilities-size-8px) 0;
  }

  .dwUtilities__main .dwUtilities__mainColumn {
    margin: 0 var(--dwUtilities-size-8px);
  }

  .dwUtilities__mainSideColumn {
    display: none;
  }

  .dwUtilities__card {
    --dwUtilities-card-padding: var(--dwUtilities-size-4px);
    --dwUtilities-card-paddingNegative: calc(-1 * var(--dwUtilities-size-4px));
  }

  .dwUtilities__card .dwUtilities__cardFooter {
    padding: var(--dwUtilities-size-12px);
  }

  .dwUtilities__footer {
    padding: var(--dwUtilities-size-8px);
  }
}

/*

  Buttons

*/

.dwUtilities__button {
  /* Button reset */
  background-image: none;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;

  /* Button styling */

  border: 2px solid var(--dwUtilities-color-navy);
  border-radius: 2px;
  background-color: var(--dwUtilities-color-white);
  color: var(--dwUtilities-color-navy);
  font-family: var(--dwUtilities-font-base);
  font-weight: bold;
  padding: var(--dwUtilities-size-8px) var(--dwUtilities-size-20px);
}

.dwUtilities__button:hover,
.dwUtilities__button:focus {
  box-shadow: var(--dwUtilities-size-4px) var(--dwUtilities-size-4px)
    var(--dwUtilities-size-8px) var(--dwUtilities-color-nickelShadow);
}

.dwUtilities__button:disabled {
  cursor: initial;
  color: var(--dwUtilities-color-nickel);
  border-color: var(--dwUtilities-color-nickel);
}

.dwUtilities__button:disabled:hover,
.dwUtilities__button:disabled:focus {
  box-shadow: none;
}

.dwUtilities__button + .dwUtilities__button {
  margin-left: var(--dwUtilities-size-8px);
}

.dwUtilities__button--primary {
  background-color: var(--dwUtilities-color-navy);
  border-color: var(--dwUtilities-color-navy);
  color: var(--dwUtilities-color-white);
}

.dwUtilities__button--primary:disabled {
  background-color: var(--dwUtilities-color-nickel);
  border-color: var(--dwUtilities-color-nickel);
  color: var(--dwUtilities-color-white);
}

.dwUtilities__button--icon {
  background-color: var(--dwUtilities-color-white);
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--dwUtilities-size-4px);
}

.dwUtilities__button--icon:hover,
.dwUtilities__button--icon:focus {
  background-color: var(--dwUtilities-color-magnesium);
}

/*

  Form

*/

.dwUtilities__formHeader {
  margin-top: 0;
}

/*

  Fields

*/

.dwUtilities__field {
  color: var(--dwUtilities-color-midnight);
  font-family: var(--dwUtilities-font-base);
  margin: var(--dwUtilities-size-16px) 0;
}

.dwUtilities__field--sm {
  margin: var(--dwUtilities-size-8px) 0;
}

.dwUtilities__label {
  font-weight: bold;
}

/*

  Fieldsets

*/
.dwUtilities__fieldset {
  font-family: var(--dwUtilities-font-base);
  border: none;
  margin: var(--dwUtilities-size-16px) 0;
  padding: 0;
}

/*

  Inputs

*/

.dwUtilities__input {
  font-family: var(--dwUtilities-font-base);
  margin: var(--dwUtilities-size-8px) 0;
  padding: var(--dwUtilities-size-8px);
  border: 1px solid var(--dwUtilities-color-navy);
  border-radius: 2px;
  min-width: 17.5rem;
}

/*

  Checkbox and Radio Inputs

*/

.dwUtilities__optionWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dwUtilities__optionInput {
  margin: 0 0 0 var(--dwUtilities-size-2px);
}

.dwUtilities__optionLabel {
  margin: 0 0 0 var(--dwUtilities-size-12px);
}

/*

  Selects

*/

.dwUtilities__select {
  margin: var(--dwUtilities-size-8px) 0;
}
